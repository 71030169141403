import React, {useContext, useRef, useState} from "react";
import {CircularProgress, Grid, IconButton, TextField, Tooltip} from "@mui/material";
import {Document, Page} from "react-pdf";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import CustomTag from "../../custom/CustomTag";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";
import dayjs from "dayjs";
import CustomSearchInput from "../../custom/CustomSearchInput";
import constants from "../../../assets/utils/constants";
import CustomRichText from "../../custom/CustomRichText";
import HelpIcon from "@mui/icons-material/Help";

const InvoiceConfig = () => {

    const containerRef = useRef(null);
    const [isOpen, setIsOpen] = React.useState(false);
    const [buttonLoading, setButtonLoading] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [pdfData, setPdfData] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [containerWidth, setContainerWidth] = useState(400);
    const { club: loggedClub, token } = useContext(AuthContext);
    const [club, setClub] = useState(loggedClub);

    const onDocumentLoadSuccess = ({ numPages }) => {
        updateWidth();
        setNumPages(numPages);
    }

    const updateWidth = () => {
        if (containerRef.current) setContainerWidth((containerRef.current.offsetWidth - 40) > 761 ? 761 : (containerRef.current.offsetWidth - 40));
    };

    React.useEffect(() => {
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    React.useEffect(() => updateWidth, [containerRef.current]);

    React.useEffect(() => {
        setLoading(true);
        const debounce = setTimeout(() => {
            axios.post(process.env.REACT_APP_ADMIN_API_URL + 'clubs/invoice-template', club, { headers: { Authorization: 'Bearer ' + token }, responseType: 'blob' })
                .then(response => {
                    const file = new Blob([response.data], { type: 'application/pdf' });
                    setPdfData(URL.createObjectURL(file));
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    setLoading(false);
                });
        }, 500);
        return () => clearTimeout(debounce);
    }, [isOpen, club]);

    const handleEditInvoice = () => {
        setButtonLoading(true);
        axios.put(process.env.REACT_APP_ADMIN_API_URL + 'clubs/invoice', club, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setIsOpen(false);
                setButtonLoading(false);
                toast.success("La facture a bien été modifiée", toastOptions);
            })
            .catch(error => {
                toast.error("Une erreur est survenue", toastOptions);
                console.log(error);
                setButtonLoading(false);
            });
    }

    const replaceDateVariables = prefix => {
        const currentDate = dayjs();
        return prefix
            .replace('(annee)', currentDate.format('YYYY'))
            .replace('(mois)', currentDate.format('MM'))
            .replace('(jour)', currentDate.format('DD'));
    }

    return (
        <div>
            <button className='main-button blue-button edit' onClick={() => setIsOpen(true)}>Configurer la facture</button>
            <div className={`invoice-config-container ${isOpen ? 'open' : ''}`}>
                <div className='invoice-config-pdf-container' ref={containerRef}>
                    { !loading ? (
                        <>
                            { pdfData && (
                                <Document file={pdfData} onLoadSuccess={onDocumentLoadSuccess} loading={
                                    <div className='pdf-loader-container'>
                                        <CircularProgress size={50}/>
                                    </div>
                                }>
                                    { Array.from(new Array(numPages), (el, index) => (
                                        <Page
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                            renderAnnotationLayer={false}
                                            renderTextLayer={false}
                                            width={containerWidth}
                                            loading={
                                                <div className='pdf-loader-container'>
                                                    <CircularProgress size={50}/>
                                                </div>
                                            }
                                        />
                                    ))}
                                </Document>
                            )}
                        </>
                    ) : (
                        <div className='pdf-loader-container'>
                            <CircularProgress size={50} />
                        </div>
                    )}
                </div>
                <div className='invoice-config-form-container'>
                    <div className='licence-sign-form-header'>
                        <h1>Configurer la facture</h1>
                        <button className='modal-close' onClick={() => setIsOpen(false)}>+</button>
                    </div>
                    <div className='invoice-config-form-body'>
                        <div className='view-subtitle user'>
                            <h2>Informations du club</h2>
                        </div>
                        <div className='margin-bottom-15'>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Raison sociale"
                                        variant="outlined"
                                        size="small"
                                        value={club.invoiceCompanyName}
                                        onChange={e => setClub({...club, invoiceCompanyName: e.target.value})}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Statut juridique"
                                        variant="outlined"
                                        size="small"
                                        value={club.invoiceLegalStatus}
                                        onChange={e => setClub({...club, invoiceLegalStatus: e.target.value})}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Adresse complète du siège social"
                                        variant="outlined"
                                        size="small"
                                        value={club.invoiceAddress}
                                        onChange={e => setClub({...club, invoiceAddress: e.target.value})}
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <TextField
                                        label="Ville"
                                        variant="outlined"
                                        size="small"
                                        value={club.invoiceCity}
                                        onChange={e => setClub({...club, invoiceCity: e.target.value})}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        label="Code postal"
                                        variant="outlined"
                                        size="small"
                                        value={club.invoicePostalCode}
                                        onChange={e => setClub({...club, invoicePostalCode: e.target.value})}
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <TextField
                                        label="Numéro SIREN"
                                        variant="outlined"
                                        size="small"
                                        value={club.sirenCode}
                                        onChange={e => setClub({...club, sirenCode: e.target.value})}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        label="Code APE"
                                        variant="outlined"
                                        size="small"
                                        value={club.apeCode}
                                        onChange={e => setClub({...club, apeCode: e.target.value})}
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <TextField
                                        label="Email"
                                        variant="outlined"
                                        size="small"
                                        value={club.invoiceEmail}
                                        onChange={e => setClub({...club, invoiceEmail: e.target.value})}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        label="Téléphone"
                                        variant="outlined"
                                        size="small"
                                        value={club.invoicePhone}
                                        onChange={e => setClub({...club, invoicePhone: e.target.value})}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="IBAN"
                                        variant="outlined"
                                        size="small"
                                        value={club.invoiceIban}
                                        onChange={e => setClub({...club, invoiceIban: e.target.value})}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="BIC"
                                        variant="outlined"
                                        size="small"
                                        value={club.invoiceBic}
                                        onChange={e => setClub({...club, invoiceBic: e.target.value})}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className='margin-bottom-15'>
                            <div className='view-subtitle flex-start process'>
                                <h2>Facture automatique par statut</h2>
                                <Tooltip enterTouchDelay={0} placement='top' id='options-tooltip' title={
                                    <React.Fragment>
                                        <CustomRichText
                                            value="Les factures seront générées pour les inscriptions dont le statut correspond à l'un des statuts sélectionnés. Cette fonction n'est pas rétroactive."
                                            readMode className="padding"/>
                                    </React.Fragment>
                                }>
                                    <IconButton aria-label="delete" size="small">
                                        <HelpIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <CustomSearchInput
                                placeholder='Choisissez un ou plusieurs statut(s)'
                                entity={constants.groupStatus.map(s => ({...s, id: s.value}))}
                                isMultiSearch={true}
                                value={club.invoiceAutoStatus?.map(status => constants.groupStatus.find(s => s.value === status))}
                                onChange={value => setClub({...club, invoiceAutoStatus: value.map(v => v.value)})}
                            />
                        </div>
                        <div className='view-subtitle'>
                            <h2>Numéro et préfixe</h2>
                        </div>
                        <div className='margin-bottom-15'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Préfixe"
                                        variant="outlined"
                                        size="small"
                                        value={club.invoicePrefix}
                                        onChange={e => setClub({...club, invoicePrefix: e.target.value})}
                                    />
                                    <div className='invoice-variables'>
                                        <span>Variables disponibles</span>
                                        <div>
                                            <small>(annee)</small>
                                            <small>(mois)</small>
                                            <small>(jour)</small>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Numéro de la prochaine facture"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        value={club.invoiceNb + 1}
                                    />
                                    <div className='invoice-variables margin-bottom'>
                                        <span>Prochaine facture :</span>
                                        <CustomTag
                                            value={`${replaceDateVariables(club.invoicePrefix)}${String(club.invoiceNb + 1).padStart(club.invoicePadStart, '0')}`}/>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Taille du numéro de facture"
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        value={club.invoicePadStart}
                                        onChange={e => setClub({...club, invoicePadStart: e.target.value})}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className='view-subtitle'>
                            <h2>Informations additionnelles</h2>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    multiline
                                    rows={4}
                                    label="Informations additionnelles"
                                    variant="outlined"
                                    size="small"
                                    value={club.invoiceAdditionalText}
                                    onChange={e => setClub({...club, invoiceAdditionalText: e.target.value})}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div className='invoice-config-form-buttons'>
                        <button className='main-button without-icon full-width'
                                onClick={() => setIsOpen(false)}>Annuler
                        </button>
                        <button className='main-button blue-button full-width without-icon' onClick={handleEditInvoice}
                                disabled={buttonLoading}>{!buttonLoading ? 'Enregistrer' :
                            <CircularProgress size={20} className="white-loader"/>}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InvoiceConfig;